import { Box, Paper, Stack } from "@mui/material";
import { Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { RepoType } from "types";
import { HDate } from "helpers";
import { Icons, Repos } from "assets/data";

// icons
import { CalendarMonthRounded } from "@mui/icons-material";

type Props = {
  item: RepoType;
};

export const Repo = (props: Props) => {
  const { theme } = useTheme();
  const { locale } = useApp();

  const iconName =
    Object.keys(Icons).find((key: string) =>
      key.startsWith(props.item.language.toLowerCase())
    ) ||
    Object.keys(Icons).find((key: string) =>
      key.startsWith(props.item.name.split("-")[0].toLowerCase())
    );

  const iconComponent = iconName
    ? Icons[iconName].color
    : theme.palette.font.color;

  return (
    <Paper
      variant="outlined"
      sx={{
        height: 1,
        borderRadius: theme.border.radius,
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.background.accent,
      }}
    >
      <Stack
        height={1}
        p={theme.spacing.md}
        spacing={theme.spacing.sm}
        aria-label={props.item.name}
      >
        <Stack direction="row" spacing={theme.spacing.sm} alignItems="center">
          <Icon.Svg name="repo" fill={theme.palette.font.color} />
          <Box
            component="a"
            target="_blank"
            href={props.item.html_url}
            sx={{
              textUnderlineOffset: 3,
              color: theme.palette.font.color,
              textDecorationLine: "underline",
              textDecorationThickness: "from-font",
            }}
          >
            {props.item.name}
          </Box>
        </Stack>
        <Box height={1}>
          <Custom.Typography>
            {Repos[props.item.name]
              ? Repos[props.item.name].briefdescription[locale.toLowerCase()]
              : ""}
          </Custom.Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={theme.spacing.sm} alignItems="center">
            <Box
              width={12}
              height={12}
              bgcolor={iconComponent}
              borderRadius={theme.border.circle}
            />
            <Custom.Typography size={theme.font.xs}>
              {props.item.language}
            </Custom.Typography>
          </Stack>
          <Stack direction="row" spacing={theme.spacing.sm} alignItems="center">
            <CalendarMonthRounded sx={{ color: theme.palette.font.color }} />
            <Custom.Typography size={theme.font.xs}>
              {HDate.formattedDate(new Date(props.item.pushed_at), locale)}
            </Custom.Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

import { Box, Grid, Paper, Stack } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom, Icon, Item } from "components";
import { Technologies } from "assets/data";
import { TechnologyType } from "types";

type Props = {};

export const Technology = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  const reduced = Technologies.reduce((acc: any, item: TechnologyType) => {
    Object.keys(item.techs).forEach((key: string) => {
      acc[key] = item.techs[key];
    });
    return acc;
  }, {});

  const sorted = Object.keys(reduced).sort((a, b) => reduced[b] - reduced[a]);

  // const helperText = props.value ? t.scale[Math.ceil(props.value / 2)] : "";

  return (
    <Box component="section" id="tech_arsenal" className="section-tech-stack">
      <Box className="section-header">
        <Custom.Typography
          variant="h4"
          sx={{
            color: theme.color.accent.text,
            backgroundColor: theme.color.accent.color,
          }}
        >
          {t.title.tech_arsenal}
        </Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="tech-stack-container">
          <Box
            sx={{
              display: "grid",
              gap: theme.spacing.xl /* Space between items */,
              gridTemplateRows: "repeat(3, 1fr)" /* 3 equal rows */,
              gridTemplateColumns: "repeat(8, 1fr)" /* 8 equal columns */,
              "@media (max-width: 1200px)": {
                // Medium screens: 6 columns
                gridTemplateColumns: "repeat(6, 1fr)",
              },
              "@media (max-width: 900px)": {
                // Smaller screens: 4 columns
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              "@media (max-width: 600px)": {
                // Mobile screens: 2 columns
                gridTemplateColumns: "repeat(3, 1fr)",
              },
            }}
          >
            {sorted.map((item) => (
              <Paper
                key={item}
                variant="outlined"
                sx={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: theme.spacing.md,
                  borderRadius: theme.border.radius,
                  borderColor: theme.palette.border,
                  backgroundColor: theme.palette.background.accent,
                }}
              >
                <Icon.Svg size={64} name={item} />
              </Paper>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

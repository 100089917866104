import { Box, Stack } from "@mui/material";
import { Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { Socials } from "assets/data";
import { SocialType } from "types";

type Props = {
  color?: string;
  size?: "small" | "medium" | "large" | undefined;
};

export const Social = (props: Props) => {
  const { theme } = useTheme();

  const iconSize: number =
    {
      small: 16,
      medium: 24,
      large: 32,
    }[props.size as string] || 32;

  return (
    <Stack direction="row">
      {Socials.map((item: SocialType) => (
        <Box
          key={item.name}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={item.href}
          aria-label={item.name}
        >
          <Custom.IconButton>
            <Icon.Svg
              name={item.icon}
              size={iconSize}
              fill={props.color ?? theme.palette.font.color}
            />
          </Custom.IconButton>
        </Box>
      ))}
    </Stack>
  );
};

import "assets/scss/components/item_project.scss";

import { Box, Paper, Stack } from "@mui/material";
import { Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { ProjectType } from "types";
import { Repository } from "assets/icons";

// icons
import { InsertLinkRounded } from "@mui/icons-material";

type Props = {
  item: ProjectType;
};

export const Project = (props: Props) => {
  const { theme } = useTheme();
  const { locale, t } = useApp();

  return (
    <Paper
      variant="outlined"
      aria-label={props.item.name}
      sx={{
        overflow: "hidden",
        borderRadius: theme.border.radius,
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.background.accent,
      }}
    >
      <Stack spacing={theme.spacing.sm}>
        {/* <Box className="thumb">
          <Box className="shadow">
            <Stack direction="row" className="icon" spacing={theme.spacing.md}>
              {props.item.stack.map((item: string) => {
                return (
                  <Icon.Dev key={item} size="medium" colored name={item} />
                );
              })}
            </Stack>
            <Stack className="description" spacing={theme.spacing.sm}>
              <Custom.Typography
                variant="h4"
                color={theme.color.white}
                weight={theme.font.semiBold}
              >
                {props.item.briefdescription[locale.toLowerCase()]}
              </Custom.Typography>
            </Stack>
          </Box>
          <Box
            component="img"
            alt={props.item.name}
            src={`images/${props.item.src}`}
          />
        </Box> */}

        <Box
          component="img"
          alt={props.item.name}
          src={`images/${props.item.src}`}
        />
        <Stack p={theme.spacing.md} spacing={theme.spacing.sm}>
          <Custom.Typography size={theme.font.md} weight={theme.font.medium}>
            {props.item.name}
          </Custom.Typography>
          <Custom.Typography size={theme.font.sm}>
            {props.item.briefdescription[locale.toLowerCase()]}
          </Custom.Typography>
          <Stack direction="row" justifyContent="space-between">
            <Custom.Typography>{props.item.platform[0]}</Custom.Typography>
            <Stack direction="row" spacing={theme.spacing.sm}>
              {props.item.stack.map((item: string) => (
                <Icon.Svg key={item} name={item} />
              ))}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {props.item.href && (
              <Stack direction="row" spacing={theme.spacing.sm}>
                <InsertLinkRounded />
                <Box
                  component="a"
                  target="_blank"
                  href={props.item.href}
                  sx={{
                    textUnderlineOffset: 3,
                    color: theme.palette.font.color,
                    textDecorationLine: "underline",
                    textDecorationThickness: "from-font",
                  }}
                >
                  {t.label.live_preview}
                </Box>
              </Stack>
            )}
            {props.item.repo && (
              <Stack direction="row" spacing={theme.spacing.sm}>
                <Icon.Svg name="github" fill={theme.palette.font.color} />
                <Box
                  component="a"
                  target="_blank"
                  href={props.item.repo}
                  sx={{
                    textUnderlineOffset: 3,
                    color: theme.palette.font.color,
                    textDecorationLine: "underline",
                    textDecorationThickness: "from-font",
                  }}
                >
                  {t.label.view_code}
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

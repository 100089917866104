import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  MenuItem,
  Stack,
} from "@mui/material";
import { DarkModeRounded, Menu as MenuIcon } from "@mui/icons-material";
import { Common, Custom } from "components";
import { useApp, useTheme } from "contexts";

type Props = {};

type SectionItem = {
  [key: string]: string;
};

export const Navbar = (props: Props) => {
  const appBarRef = React.useRef<HTMLElement | null>(null);

  const { theme, toggleTheme } = useTheme();
  const { locale, locales, setLocale, t } = useApp();

  const [onTop, setOnTop] = useState(true);
  const [localeMenu, setLocaleMenu] = useState<HTMLElement | null>(null);
  const [navigationMenu, setNavigationMenu] = useState<HTMLElement | null>(
    null
  );

  const sections: SectionItem = {
    overview: t.title.overview,
    tech_arsenal: t.title.tech_arsenal,
    project: t.title.project,
    repo: t.title.repo,
    history: t.title.history,
  };

  const textColor = onTop ? theme.palette.font.color : theme.color.accent.text;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function handleThemeClick() {
    toggleTheme();
  }

  function handleNavigationOpen(event: React.MouseEvent<HTMLElement>) {
    setNavigationMenu(event.currentTarget);
  }

  function handleNavigationClose() {
    setNavigationMenu(null);
  }

  function handleLocaleOpen(event: React.MouseEvent<HTMLElement>) {
    setLocaleMenu(event.currentTarget);
  }

  function handleLocaleClose() {
    setLocaleMenu(null);
  }

  function handleSetLocale(locale: string) {
    setLocale(locale);
    handleLocaleClose();
  }

  function handleScrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      const { clientHeight = 0 } = appBarRef.current as HTMLElement;
      window.scrollTo(0, element.offsetTop - clientHeight);
    }
    handleNavigationClose();
  }

  function handleScrollTop() {
    window.scrollTo(0, 0);
  }

  function handleScroll() {
    setOnTop(window.scrollY <= theme.spacing.xxl * theme.spacing.factor);
  }

  return (
    <AppBar
      ref={appBarRef}
      position={onTop ? "relative" : "sticky"}
      sx={{
        boxShadow: onTop ? "initial" : 0,
        backgroundColor: onTop ? "transparent" : "primary.main",
      }}
    >
      <Toolbar disableGutters>
        <Common.Logo
          color={textColor}
          onClick={handleScrollTop}
          sx={{
            display: { xs: "none", lg: "flex" },
          }}
        />
        <Box flexGrow={1} display={{ xs: "flex", lg: "none" }}>
          <Custom.IconButton
            size="large"
            foreground={textColor}
            onClick={handleNavigationOpen}
          >
            <MenuIcon />
          </Custom.IconButton>
          <Custom.Menu
            id="navigation-menu"
            anchorEl={navigationMenu}
            onClose={handleNavigationClose}
            sx={{
              display: { xs: "block", lg: "none" },
            }}
          >
            {Object.keys(sections).map((key: string) => (
              <MenuItem key={key} onClick={() => handleScrollTo(key)}>
                {sections[key]}
              </MenuItem>
            ))}
          </Custom.Menu>
        </Box>{" "}
        <Common.Logo
          color={textColor}
          onClick={handleScrollTop}
          sx={{
            width: 1,
            justifyContent: "center",
            display: { xs: "flex", lg: "none" },
          }}
        />
        <Stack direction="row" ml="auto" spacing={theme.spacing.sm}>
          <Box display={{ xs: "none", lg: "flex" }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing.sm}
            >
              {Object.keys(sections).map((key: string) => (
                <Custom.Button
                  text
                  key={key}
                  onClick={() => handleScrollTo(key)}
                  sx={{
                    color: textColor,
                  }}
                >
                  {sections[key]}
                </Custom.Button>
              ))}
            </Stack>
          </Box>
          <Box flexGrow={1}>
            <Custom.IconButton
              size="medium"
              foreground={textColor}
              onClick={handleLocaleOpen}
            >
              {locale}
            </Custom.IconButton>
            <Custom.Menu
              id="locale-menu"
              anchorEl={localeMenu}
              onClose={handleLocaleClose}
            >
              {locales.map((item: string) => (
                <MenuItem key={item} onClick={() => handleSetLocale(item)}>
                  {item}
                </MenuItem>
              ))}
            </Custom.Menu>
          </Box>
        </Stack>
        <Custom.IconButton
          size="large"
          foreground={textColor}
          onClick={handleThemeClick}
        >
          <DarkModeRounded />
        </Custom.IconButton>
      </Toolbar>
    </AppBar>
  );
};

import { Box } from "@mui/material";
import InlineSVG from "react-inlinesvg";

type Props = {
  name: string;
  size?: number;
  fill?: string;
};

export const Svg = ({ name, size = 24, fill = "currentColor" }: Props) => {
  return (
    <Box component="div" display="flex" title={name} aria-label={name}>
      <InlineSVG
        fill={fill}
        width={size}
        height={size}
        src={require(`assets/icons/${name}.svg`)}
      />
    </Box>
  );
};

import React from "react";
import { Box, Stack } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom, Icon, Item } from "components";
import { Activities } from "assets/data";
import { ActivityType } from "types";
import { Constants } from "utils";

type Props = {};

export const Activity = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  const groupByYear = (data: ActivityType[]) => {
    const grouped: Record<string, ActivityType[]> = {};

    data.forEach((item) => {
      const startYear = new Date(item.from).getFullYear();
      const endYear = item.to
        ? new Date(item.to).getFullYear()
        : new Date().getFullYear();
      const yearKey =
        startYear === endYear ? `${startYear}` : `${startYear}-${endYear}`;

      if (!grouped[yearKey]) {
        grouped[yearKey] = [];
      }
      grouped[yearKey].push(item);
    });

    const sorted = Object.keys(grouped);

    sorted.sort((a, b) => {
      const parseKey = (key: string) => {
        const parts = key.split("-").map(Number);
        return parts.length === 1 ? [parts[0], parts[0]] : parts; // Single years become [year, year]
      };

      const [aStart, aEnd] = parseKey(a);
      const [bStart, bEnd] = parseKey(b);

      if (aEnd !== bEnd) return bEnd - aEnd;
      else return bStart - aStart;
    });

    return { sorted, grouped };
  };

  const { sorted, grouped } = groupByYear(Activities);

  const renderYearGroup = (key?: string, children?: React.ReactNode) => (
    <Box className="timeline-year-group">
      <Box className="timeline-year" bgcolor={theme.palette.background.color}>
        <Box className="timeline-icon">
          <Icon.Svg name="version" fill={theme.palette.font.color} />
        </Box>
        <Stack direction="row" alignItems="center" spacing={theme.spacing.xs}>
          <Custom.Typography sx={{ opacity: 0.7 }}>
            {key ? t.message.history_step : t.message.history_bottom}
          </Custom.Typography>
          {key &&
            key
              .split("-")
              .map((year: string, index: number, array: string[]) => (
                <Stack key={index} direction="row" spacing={theme.spacing.xs}>
                  <Custom.Typography
                    size={theme.font.md}
                    weight={theme.font.bold}
                  >
                    {year}
                  </Custom.Typography>
                  {index < array.length - 1 && (
                    <Custom.Typography
                      size={theme.font.md}
                      weight={theme.font.bold}
                    >
                      {Constants.SEPARATOR}
                    </Custom.Typography>
                  )}
                </Stack>
              ))}
        </Stack>
      </Box>
      {children}
    </Box>
  );

  return (
    <Box component="section" id="history" className="section-history">
      <Box className="section-header">
        <Custom.Typography
          variant="h4"
          sx={{
            color: theme.color.accent.text,
            backgroundColor: theme.color.accent.color,
          }}
        >
          {t.title.history}
        </Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="history-container">
          <Box
            className="history-timeline"
            sx={{
              "--timeline-line-color": theme.palette.border,
            }}
          >
            {sorted.map((key: string) => (
              <Box key={key} className="timeline-year-group">
                {renderYearGroup(
                  key,
                  grouped[key].map((activity: ActivityType, i: number) => (
                    <Item.Activity
                      key={i}
                      item={activity}
                      head={i === 0}
                      tail={i === grouped[key].length - 1}
                    />
                  ))
                )}
              </Box>
            ))}
            {renderYearGroup()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Common, Custom } from "components";
import { useApp, useTheme } from "contexts";
import { HDate, HString } from "helpers";
import { Constants } from "utils";

// icons
import { FavoriteRounded } from "@mui/icons-material";

type Props = {};

export const Footer = (props: Props) => {
  const { locale, t } = useApp();
  const { theme } = useTheme();

  return (
    <Box height={60} component="footer" bgcolor={theme.palette.theme}>
      <Stack
        height={1}
        direction="row"
        alignItems="center"
        px={theme.spacing.xl}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={theme.spacing.xs}>
          {t.footer.prefix && (
            <Custom.Typography color={theme.palette.font.color}>
              {HString.interpolate(t.footer.prefix, {
                year: new Date().toLocaleDateString(locale, {
                  year: "numeric",
                }),
              })}
            </Custom.Typography>
          )}
          <FavoriteRounded sx={{ color: theme.color.status.error.color }} />
          {t.footer.suffix && (
            <Custom.Typography color={theme.palette.font.color}>
              {t.footer.suffix}
            </Custom.Typography>
          )}
        </Stack>
        <Box>
          <Common.Social size="medium" color={theme.palette.font.color} />
        </Box>
      </Stack>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useApp, useService, useTheme } from "contexts";
import { Custom, Item } from "components";
import { RepoType } from "types";
import { motion } from "framer-motion";

type Props = {};

export const Repo = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const { github } = useService();

  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [repositories, setRepositories] = useState<RepoType[]>([]);

  useEffect(() => {
    fetchRepositories();
  }, [page]);

  async function fetchRepositories() {
    const count = 9;
    const response = await github.all(page, count);
    setHasMore(response.length % count === 0);
    setRepositories(response);
  }

  function loadMore() {
    setPage(page + 1);
  }

  return (
    <Box component="section" id="repo" className="section-repo">
      <Box className="section-header">
        <Custom.Typography
          variant="h4"
          sx={{
            color: theme.color.accent.text,
            backgroundColor: theme.color.accent.color,
          }}
        >
          {t.title.repo}
        </Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="repo-container">
          <Box className="content">
            <Grid container spacing={theme.spacing.md}>
              {repositories.map((item: RepoType) => {
                return (
                  <Grid key={item.id} item xs={12} sm={6} lg={4}>
                    <motion.div
                      style={{ height: "100%" }}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Item.Repo item={item} />
                    </motion.div>
                  </Grid>
                );
              })}
              {hasMore && (
                <Grid item xs={12} textAlign="center">
                  <Custom.Button
                    text
                    onClick={loadMore}
                    sx={{ color: theme.palette.font.color }}
                  >
                    {t.label.load_more}
                  </Custom.Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

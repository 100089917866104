import { GithubData, IGithubData } from "data";
import { RepoType } from "types";

export interface IGithubService {
  all(page: number, count: number): Promise<RepoType[]>;
}

export class GithubService implements IGithubService {
  githubData: IGithubData;

  constructor() {
    this.githubData = new GithubData();
  }

  /**
   * get all the respositories of the user
   * @param page {number} The page number
   * @param count {number} The number of items per page
   * @returns 
   */

  async all(page: number, count: number) {
    const response = await this.githubData.all();
    if (response.data) {
      return response.data.filter((item: RepoType) => !item.archived)
        .sort((a: RepoType, b: RepoType) => new Date(b.pushed_at).getTime() - new Date(a.pushed_at).getTime())
        .slice(0, page * count);
    }
  }
}
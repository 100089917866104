import React from "react";
import { Box, Grid } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom } from "components";

type Props = {};

export const Overview = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="overview" className="section-overview">
      <Box className="section-header">
        <Custom.Typography
          variant="h4"
          sx={{
            color: theme.color.accent.text,
            backgroundColor: theme.color.accent.color,
          }}
        >
          {t.title.overview}
        </Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="overview-container">
          <Box className="content">
            <Grid container alignItems="center" spacing={theme.spacing.md}>
              {t.overview.list.map(
                (
                  item: { title: string; short_description: string },
                  i: number
                ) => (
                  <Grid key={i} item xs={12} md={6}>
                    <Custom.Typography
                      variant="h4"
                      weight={theme.font.semiBold}
                    >
                      {item.title}
                    </Custom.Typography>
                    <Custom.Typography sx={{ ml: theme.spacing.xs }}>
                      {item.short_description}
                    </Custom.Typography>
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import React from "react";
import { Box, Grid } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Custom, Item } from "components";
import { ProjectType } from "types";
import { Projects } from "assets/data";

type Props = {};

export const Project = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();
  const projects = Projects.filter((item: ProjectType) => item.active);

  return (
    <Box component="section" id="project" className="section-project">
      <Box className="section-header">
        <Custom.Typography
          variant="h4"
          sx={{
            color: theme.color.accent.text,
            backgroundColor: theme.color.accent.color,
          }}
        >
          {t.title.project}
        </Custom.Typography>
      </Box>
      <Box className="section-content">
        <Box className="project-container">
          <Box className="content">
            <Grid container spacing={theme.spacing.md}>
              {projects.map((item: ProjectType, i: number) => {
                return (
                  <Grid key={i} item xs={12} sm={6} md={4}>
                    <Item.Project item={item} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

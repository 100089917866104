import "assets/scss/components/item_history.scss";

import { Box, Grid, Paper, Stack } from "@mui/material";
import { Custom, Icon } from "components";
import { useApp, useTheme } from "contexts";
import { ActivityType } from "types";
import { HDate, HString } from "helpers";

// icons
import { BusinessRounded, CalendarMonthRounded } from "@mui/icons-material";
import { Constants } from "utils";

type Props = {
  item: ActivityType;
  head: boolean;
  tail: boolean;
  hasCalculation?: boolean;
};

export const Activity = ({ hasCalculation = false, ...props }: Props) => {
  const { locale, t } = useApp();
  const { theme } = useTheme();

  const isWork = props.item.is === "work";

  const calculateDuration = (
    from: string,
    to: string = new Date().toDateString()
  ) => {
    const start = new Date(from);
    const end = new Date(to);
    const diff = end.getTime() - start.getTime();
    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    return years === 0
      ? HString.interpolate(
          remainingMonths === 1
            ? t.date.timespan.month.singular
            : t.date.timespan.month.plural,
          { count: remainingMonths }
        )
      : remainingMonths === 0
      ? HString.interpolate(
          years === 1
            ? t.date.timespan.year.singular
            : t.date.timespan.year.plural,
          { count: years }
        )
      : `${HString.interpolate(
          years === 1
            ? t.date.timespan.year.singular
            : t.date.timespan.year.plural,
          { count: years }
        )} ${t.conjunction.and} ${HString.interpolate(
          remainingMonths === 1
            ? t.date.timespan.month.singular
            : t.date.timespan.month.plural,
          { count: remainingMonths }
        )}`;
  };

  const borderRadius = theme.border.radius * theme.border.factor;

  return (
    <Box className="timeline-item">
      <Paper
        variant="outlined"
        className="content"
        sx={{
          borderRadius: 0,
          borderColor: theme.palette.border,
          backgroundColor: theme.palette.background.accent,
          borderTopLeftRadius: props.head ? borderRadius : undefined,
          borderTopRightRadius: props.head ? borderRadius : undefined,
          borderBottomLeftRadius: props.tail ? borderRadius : undefined,
          borderBottomRightRadius: props.tail ? borderRadius : undefined,
        }}
      >
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Custom.Typography size={theme.font.md} weight={theme.font.normal}>
              {props.item.as[locale.toLowerCase()]}
            </Custom.Typography>
            <Custom.Chip
              selected
              size="small"
              label={props.item.type[locale.toLowerCase()]}
            />
          </Stack>
          <Stack
            direction="row"
            sx={{ opacity: 0.7 }}
            justifyContent="space-between"
            color={theme.palette.font.color}
            gap={{
              xs: theme.spacing.xs,
            }}
            flexWrap={{
              xs: "wrap",
            }}
          >
            <Stack
              direction="row"
              spacing={theme.spacing.sm}
              alignItems="center"
            >
              <BusinessRounded />
              <Custom.Typography size={theme.font.xs}>
                {props.item.at} {Constants.SEPARATOR}{" "}
                {props.item.in[locale.toLowerCase()]}
              </Custom.Typography>
            </Stack>
            <Stack
              direction="row"
              spacing={theme.spacing.sm}
              alignItems="center"
            >
              <CalendarMonthRounded />
              <Custom.Typography size={theme.font.xs}>
                {HDate.formattedDate(
                  new Date(props.item.from),
                  locale.toLowerCase()
                )}{" "}
                {Constants.SEPARATOR}{" "}
                {props.item.to
                  ? HDate.formattedDate(
                      new Date(props.item.to),
                      locale.toLowerCase()
                    )
                  : t.label.present}{" "}
                {hasCalculation &&
                  `(${calculateDuration(props.item.from, props.item.to)})`}
              </Custom.Typography>
            </Stack>
          </Stack>
        </>
      </Paper>
    </Box>
  );
};

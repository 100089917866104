import { Box, Stack, SxProps } from "@mui/material";
import { Brand } from "assets/images";
import { useApp, useTheme } from "contexts";
import { Custom } from "components";

type Props = {
  sx: SxProps;
  color?: string;
  onClick: () => void;
};

export const Logo = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Stack
      sx={props.sx}
      direction="row"
      alignItems="center"
      className="c-pointer"
      onClick={props.onClick}
      spacing={theme.spacing.sm}
    >
      <Box display="flex" width={48} mr={theme.spacing.xs}>
        <Brand fill={props.color} />
      </Box>
      <Custom.Typography
        noWrap
        variant="h4"
        color={props.color}
        mr={theme.spacing.md}
        weight={theme.font.medium}
      >
        {t.navbar.title}
      </Custom.Typography>
    </Stack>
  );
};

import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useApp, useTheme } from "contexts";
import { Common, Custom } from "components";
import { User } from "assets/images";

type Props = {};

export const Intro = (props: Props) => {
  const { t } = useApp();
  const { theme } = useTheme();

  return (
    <Box component="section" id="intro" className="section-intro">
      <Box className="section-content">
        <Box className="intro-container" mt={theme.spacing.xxl}>
          <Box className="content">
            <Grid container alignItems="center" spacing={theme.spacing.md}>
              <Grid item xs={12} md={6}>
                <Stack spacing={theme.spacing.sm}>
                  <Custom.Typography variant="h1">
                    {t.intro.header}
                  </Custom.Typography>
                  <Custom.Typography
                    variant="h3"
                    lineHeight={1.65}
                    sx={{ opacity: 0.7 }}
                    weight={theme.font.thin}
                  >
                    {t.intro.headline}
                  </Custom.Typography>
                  {t.intro.content.map((paragraph: string, i: number) => {
                    return (
                      <Custom.Typography
                        key={i}
                        variant="h4"
                        lineHeight={2}
                        weight={theme.font.normal}
                      >
                        {paragraph}
                      </Custom.Typography>
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={{ md: theme.spacing.xl }}>
                  <Box
                    width={1}
                    src={User}
                    component="img"
                    borderRadius={theme.border.radius}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Common.Social />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { ActivityType, DevIconType, ProjectType, RepoMapperType, SocialType, TechnologyType } from "types";

import Activity from "assets/data/activity.json";
import IconMapper from "assets/data/icon_mapper.json";
import Project from "assets/data/project.json";
import Repo from "assets/data/repo.json";
import Social from "assets/data/social.json";
import Technology from "assets/data/technology.json";

export const Activities = Activity as unknown as ActivityType[];
export const Icons = IconMapper as DevIconType;
export const Projects = Project as unknown as ProjectType[];
export const Repos = Repo as unknown as RepoMapperType;
export const Socials = Social as unknown as SocialType[];
export const Technologies = Technology as unknown as TechnologyType[];
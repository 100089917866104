import { en, fr, pt, sg } from "assets/locales"

export const REGIONS = {
  EN: "EN",
  FR: "FR",
  PT: "PT",
  SG: "SG"
};

export const LOCALE_STRINGS = {
  [REGIONS.EN]: en,
  [REGIONS.FR]: fr,
  [REGIONS.PT]: pt,
  [REGIONS.SG]: sg
};

export const DATE_FORMAT = {
  DEFAULT: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  YEAR_MONTH: {
    year: "numeric",
    month: "short",
  }
}

export const TIME_FORMAT = {
  DEFAULT: {
    hour: "2-digit",
    minute: "2-digit",
  }
}

export const INPUT_MASK = {
  DATE: "9999-99-99",
  PHONE: "(999) 999-9999"
}

export const TEXTAREA = {
  MAX: 5,
  MIN: 3
};

export const MAX_INTEGER = 2147483647;

export const SEPARATOR = " — ";

export const GITHUB = {
  BASE_URL: process.env.REACT_APP_GITHUB_API_BASE_URL,
}
import React from "react";
import { IconButton as MUIIconButton, IconButtonProps } from "@mui/material";
import { useTheme } from "contexts";

type Props = IconButtonProps & {
  children: React.ReactNode;
  foreground?: string | undefined;
};

export const IconButton = (props: Props) => {
  const { theme } = useTheme();

  return (
    <MUIIconButton
      {...props}
      sx={{ color: props.foreground ?? theme.palette.font.color, ...props.sx }}
    >
      {props.children}
    </MUIIconButton>
  );
};
